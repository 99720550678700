(function() {
  const ALL_COURSES = "ALL_COURSES";
  const ALL_DEGREES = "ALL_DEGREES";
  const IS_RETURNING_STUDENT = "IS_RETURNING_STUDENT";
  const MY_COURSES = "MY_COURSES";
  const MY_DEGREE = "MY_DEGREE";
  const TAKEN_COURSES = "MY_TAKEN_COURSES";

  function clearApp() {
    localStorage.clear();
  }

  function clearMyCourses() {
    localStorage.removeItem(MY_COURSES);
  }

  function clearTakenCourses() {
    localStorage.removeItem(TAKEN_COURSES);
  }

  function clearMyCourseByShortName(courseShortName) {
    const courses = getMyCourses();
    if (!courses) return;
    delete courses[courseShortName];
    storeMyCourses(courses);
  }

  function clearTakenCourseByShortName(courseShortName) {
    const courses = getTakenCourses();
    if (!courses) return;
    delete courses[courseShortName];
    storeTakenCourses(courses);
  }

  function clearMyDegree() {
    localStorage.removeItem(MY_DEGREE);
  }
  
  function getAllCourses() {
    return JSON.parse(localStorage.getItem(ALL_COURSES));
  }

  function getAllDegrees() {
    return JSON.parse(localStorage.getItem(ALL_DEGREES));
  };
  /**
   * Functions gets a short name and returns a course matching that short name. 
   * @param {string} shortName 
   */
  function getCourseByShortName(shortName) {
    const courses = getAllCourses();
    return courses[shortName];
  }

  function getDegreeByKey(degreeKey) {
    const degrees = getAllDegrees();
    if (!degrees) return;
    return degrees[degreeKey];
  }

  function getMyCourses() {
    return JSON.parse(localStorage.getItem(MY_COURSES));
  }
  
  function getTakenCourses(){
    return JSON.parse(localStorage.getItem(TAKEN_COURSES));
  }

  function getMyCourseByShortName(courseShortName) {
    const courses = getMyCourses();
    if (!courses) return;
    return courses[courseShortName];
  }

  function getTakenCourseByShortName(courseShortName) {
    const courses = getTakenCourses();
    if (!courses) return;
    return courses[courseShortName];
  }

  function getMyDegree() {
    return JSON.parse(localStorage.getItem(MY_DEGREE));
  }

  function getIsReturningStudent() {
    return localStorage.getItem(IS_RETURNING_STUDENT) === "true";
  }

  function storeAllCourses(courses) {
    localStorage.setItem(ALL_COURSES, JSON.stringify(courses));
  }

  function storeAllDegrees(degrees) {
    localStorage.setItem(ALL_DEGREES, JSON.stringify(degrees));
  }

  function storeMyCourses(courses) {
    localStorage.setItem(MY_COURSES, JSON.stringify(courses));
  }

  function storeTakenCourses(courses) {
    localStorage.setItem(TAKEN_COURSES, JSON.stringify(courses));
  }

  function storeMyDegree(degree) {
    localStorage.setItem(MY_DEGREE, JSON.stringify(degree));
  }

  function storeIsReturningStudent(value) {
    localStorage.setItem(IS_RETURNING_STUDENT, value);
  }

  window._APP = {...window._APP,
    clearApp,
    clearMyCourses,
    clearMyCourseByShortName,
    clearMyDegree,
    clearTakenCourses,
    clearTakenCourseByShortName,
    getAllCourses,
    getAllDegrees,
    getCourseByShortName,
    getDegreeByKey,
    getMyCourses,
    getTakenCourses,
    getMyCourseByShortName,
    getTakenCourseByShortName,
    getMyDegree,
    getIsReturningStudent,
    storeAllCourses,
    storeAllDegrees,
    storeMyCourses,
    storeTakenCourses,
    storeMyDegree,
    storeIsReturningStudent,
    ALL_COURSES,
    ALL_DEGREES,
    MY_COURSES,
    MY_DEGREE,
    IS_RETURNING_STUDENT,
  };

})();